import React, { Fragment } from 'react';
import Header from '@components/Header';
import Page from '@components/Page';
import { graphql } from 'gatsby';
import GatsbyImage from 'gatsby-image';
import { css } from '@emotion/react';
import Footer from '@components/Footer';
import { has } from 'lodash';
import BackgroundImage from 'gatsby-background-image';
import FrontpageLink from '@components/FrontpageLink';
import ResponsiveVideo from '@components/ui/ResponsiveVideo';
import PageTitle from '@components/ui/PageTitle';
import CtaButton from '@components/ui/CtaButton';
import TestimonialBlock from '@components/ui/TestimonialBlock';
import Hero from '@components/theme/Hero';
import Section from '@components/theme/Section';
import { getFirstBlock } from '@lib/blocks';

const utmMedium = 'videos';

export default function FeaturesTemplate({
  data: {
    testimonialsPage,
    videosPage,
    featuresPage
  },
  pageContext: { language, refs, layouts, configuration }
}) {
  const testimonials = getFirstBlock(testimonialsPage);
  const videos = getFirstBlock(videosPage);
  const features = getFirstBlock(featuresPage);
  const {
    featuresTitle: title,
    featuresSubtitle: subtitle,
    featuresSectionTitle: sectionTitle,
    featuresBenefits: benefits,
    featuresList: list,
    featuresTodoTitle: todoTitle,
    featuresTodoSteps: todoSteps,
    link
  } = features;
  return (
    <Page>
      <Hero title={title}>
        <Header
          language={language}
          refs={refs}
          layouts={layouts}
        />
      </Hero>
      <Section>
        <div css={css`
          padding: 4rem 0;
        `}
        >
          <h1 className="text-center pb-2 mb-5">
            {subtitle}
          </h1>
          <div className="container">
            <div className="row">
              {benefits.map(({ entry }, i) => {
                return (
                  <div className="col" key={i}>
                    <div className="text-center">
                      {has(entry, 'entryImage.localFile.sharp.fixed') && (
                        <GatsbyImage
                          fixed={entry.entryImage.localFile.sharp.fixed}
                        >
                        </GatsbyImage>
                      )}
                    </div>
                    <div className="text-center">
                      <h2>{entry.entryTitle}</h2>
                    </div>
                  </div>
                );
              })}
            </div>
            <CtaButton link={link} utmMedium={utmMedium} utmContent="cta_button_benefits" />
          </div>
        </div>
      </Section>
      <Section
        className='bg-primary'
        dividerTop={{ variant: 3 }}
        dividerBottom={{ variant: 1 }}
      >
        <div className="container">
          <h1 className="text-center text-white text-uppercase font-weight-light pb-2 mb-5">
            {sectionTitle}
          </h1>
          <div className="row">
            {list.map(({ entry }, i) => {
              return (
                <div className="col-md-4 mb-5" key={i}>
                  <div
                    className="card card-body"
                    css={css`height: 100%;`}
                  >
                    <div className="text-center">
                      {has(entry, 'entryImage.localFile.sharp.fixed') && (
                        <GatsbyImage
                          fixed={entry.entryImage.localFile.sharp.fixed}
                        />
                      )}
                    </div>
                    <div className="text-center">
                      <h4>{entry.entryTitle}</h4>
                      <div dangerouslySetInnerHTML={{ __html: entry.entryDescription }} />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <CtaButton link={link} utmMedium={utmMedium} utmContent="cta_button_features" dark />
        </div>
      </Section>
      <Section>
        <h1 className="text-center mt-5">{videos.title}</h1>
        <div className="container py-5">
          <div className="row">
            {videos.videos.map(({ title, videoId, description }, i) =>
              <div className="col" key={i}>
                <ResponsiveVideo title={title} videoId={videoId} />
                <h2 className="text-center mt-3">{description}</h2>
              </div>
            )}
          </div>
          <CtaButton
            link={videos.link}
            utmMedium={utmMedium}
            utmContent="cta_button_videos"
          />
        </div>
      </Section>
      <Section
        className='bg-primary-alt'
        dividerTop={{ variant: 3 }}
        dividerBottom={{ variant: 4 }}
      >
        <h1 className="text-center mt-5">{testimonials.title}</h1>
        <div className='container py-5'>
          {testimonials.testimonials.map((testimonial, index) =>
            <TestimonialBlock key={index} testimonial={testimonial} />
          )}
        </div>
      </Section>
      <Section>
        <h1 className="text-center mt-5">{todoTitle}</h1>
        <div className='container py-5'>
          <div>
            {todoSteps.map(({ featuresTodoStep: step }, index) =>
              <div className="d-flex mb-4" key={index}>
                <div className="process-circle bg-primary" />
                <div className="d-flex align-items-center ml-3">
                  <h5 className="mb-0">{`${index + 1}. ${step}`}</h5>
                </div>
              </div>
            )}
          </div>
          <CtaButton
            link={link}
            utmMedium={utmMedium}
            utmContent="cta_button_steps"
          />
        </div>
      </Section>
      <Footer
        configuration={configuration}
        language={language}
        layouts={layouts}
      />
    </Page>
  );
}

export const query = graphql`
  query($id: String, $featuresId: String, $videosId: String, $testimonialsId: String) {
    page: wpWebsite(id: { eq: $id }) {
      ...Page
    }
    testimonialsPage: wpCustomPage(id: { eq: $testimonialsId }) {
      block {
        block {
          ... on WpCustomPage_Block_Block_TestimonialsSection {
            title
            link {
              linkUrl
              linkText
            }
            testimonials {
              name
              authorName
              authorProfession
              text
              image {
                localFile {
                  sharp: childImageSharp {
                    fixed(width: 160, height: 160) {
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
              authorImage {
                localFile {
                  sharp: childImageSharp {
                    fixed(width: 160, height: 160) {
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    videosPage: wpCustomPage(id: { eq: $videosId }) {
      block {
        block {
          ... on WpCustomPage_Block_Block_VideosSection {
            title
            link {
              linkText
              linkUrl
              linkStyle
            }
            videos {
              title
              videoId
              description
              type
            }
          }
        }
      }
    }
    featuresPage: wpCustomPage(id: { eq: $featuresId }) {
      language {
        code
      }
      block {
        block {
          ... on WpCustomPage_Block_Block_FeaturesSection {
            featuresTitle
            featuresSubtitle
            featuresSectionTitle
            featuresTodoTitle
            featuresTodoSteps {
              featuresTodoStep
            }
            featuresBenefits {
              entry {
                entryDescription
                entryStyle
                entryTitle
                entryImage {
                  localFile {
                    sharp: childImageSharp {
                      fixed(width: 160, height: 160) {
                        ...GatsbyImageSharpFixed
                      }
                    }
                  }
                }
              }
            }
            featuresList {
              entry {
                entryDescription
                entryStyle
                entryTitle
                entryImage {
                  localFile {
                    sharp: childImageSharp {
                      fixed(width: 140, height: 140) {
                        ...GatsbyImageSharpFixed
                      }
                    }
                  }
                }
              }
            }
            link {
              linkStyle
              linkText
              linkUrl
            }
          }
        }
      }
    }
  }
`;